export const themes = [
    {
        background: "#0d4b53",
        titleColor: "#fbc057",
        color: "#fff",
        font: "'Source Sans Pro', sans-serif"
    },
    {
        background: "#0d0d0d",
        color: "#fff",
        titleColor: '#b77de0',
        font: "'Roboto Mono', monospace"   
    },
    {
        background: "#a1734f",
        color: "#fff",
        titleColor: "#edcdb5",
        font: "'Teko', sans-serif"
    },
    {
        background: "#848e65",
        color: "#3d3c43",
        titleColor: "#fff",
        font: "'Roboto Mono', monospace"   
    }

];
